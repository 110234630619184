<template>
    <modal ref="modalAddMaterial" titulo="Añadir material" adicional="Añadir" @adicional="createOrder">
        <ValidationObserver ref="validator">
            <div class="row my-3 mx-0 f-14">
                <div class="col-12 my-2">
                    <p class="input-label-top">Categoría</p>
                    <ValidationProvider name="categoría" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_categoria" filterable placeholder="Seleccionar categoría" size="small" class="w-100" @change="getMaterials">
                            <el-option v-for="item in categorias" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Materiales</p>
                    <ValidationProvider name="materiales" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_material" filterable placeholder="Seleccionar" size="small" class="w-100" @change="getEspesores">
                            <el-option v-for="item in materiales" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Espesor</p>
                    <ValidationProvider name="espesor" v-slot="{ errors }" rules="required">
                        <el-select v-model="obj.id_espesor" filterable placeholder="Seleccionar" size="small" class="w-100">
                            <el-option v-for="item in espesor" :key="item.id" :label="item.espesor" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-input v-model="obj.unidad_medida" placeholder="U.M" size="small" class="w-100" disabled />
                </div>
                <div class="col-12 my-2">
                    <p class="input-label-top">Tipo de material</p>
                    <el-input v-model="obj.tipo_material" placeholder="Tipo de material" size="small" class="w-100" disabled />
                </div>
                <div class="col-6 my-2">
                    <p class="input-label-top">Cantidad</p>
                    <ValidationProvider name="Cantidad" v-slot="{ errors }" rules="required">
                        <el-input v-model="obj.cantidad" placeholder="Agregar cantidad" size="small" class="w-100" />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    data(){
        return {
            obj: {
                id_categoria: '',
                id_material: '',
                id_espesor: '',
                unidad_medida: '',
                tipo_material: '',
                cantidad: '',
            }
        }
    },
    computed: {
        ...mapGetters({
            categorias: 'selects/selects/select_categorias',
            materiales: 'selects/selects/select_materiales_2',
            espesor: 'selects/selects/select_espesor'
        }),
        id(){
            return this.$route.params.id_pedido;
        }
    },
    methods: {
        ...mapActions({
            Action_get_select_materiales_2: 'selects/selects/Action_get_select_materiales_2',
            Action_get_select_espesor: 'selects/selects/Action_get_select_espesor',
            Action_crear_pedido_interno_material: 'almacen/pedidos/Action_crear_pedido_interno_material'
        }),
        toggle(){
            this.$refs.modalAddMaterial.toggle()
        },
        async getMaterials(id_categoria){
            await this.Action_get_select_materiales_2({id_categoria : id_categoria})
            this.obj.id_material = ''
            this.obj.id_espesor = ''
        },
        async getEspesores(id_material){
            await this.Action_get_select_espesor({id_material: id_material})
            this.materiales.map(element => {
                if(element.id == id_material){
                    this.obj.unidad_medida = element.unidad_medida
                    this.obj.tipo_material = element.tipo_material
                }
            });
            this.obj.id_espesor = ''
        },
        async createOrder(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return 
            this.$refs.modalAddMaterial.toggle()
            await this.Action_crear_pedido_interno_material({id: this.id , payload: this.obj})
            this.$emit('update')
            this.clear()
        },
        clear(){
            this.obj = {
                id_categoria: '',
                id_material: '',
                id_espesor: '',
                unidad_medida: '',
                tipo_material: '',
                cantidad: '',
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
