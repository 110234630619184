var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalEditMaterial",attrs:{"titulo":"Editar material","adicional":"Guardar"},on:{"adicional":_vm.editOrder}},[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row my-3 mx-0 f-14"},[_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Categoría")]),_c('ValidationProvider',{attrs:{"name":"categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar categoría","size":"small"},on:{"change":_vm.getMaterials},model:{value:(_vm.obj.id_categoria),callback:function ($$v) {_vm.$set(_vm.obj, "id_categoria", $$v)},expression:"obj.id_categoria"}},_vm._l((_vm.categorias),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Materiales")]),_c('ValidationProvider',{attrs:{"name":"materiales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar","size":"small"},on:{"change":_vm.getEspesores},model:{value:(_vm.obj.id_material),callback:function ($$v) {_vm.$set(_vm.obj, "id_material", $$v)},expression:"obj.id_material"}},_vm._l((_vm.materiales),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Espesor")]),_c('ValidationProvider',{attrs:{"name":"espesor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","placeholder":"Seleccionar","size":"small"},model:{value:(_vm.obj.id_espesor),callback:function ($$v) {_vm.$set(_vm.obj, "id_espesor", $$v)},expression:"obj.id_espesor"}},_vm._l((_vm.espesor),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.espesor,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Unidad de medida")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"U.M","size":"small","disabled":""},model:{value:(_vm.obj.unidad_medida),callback:function ($$v) {_vm.$set(_vm.obj, "unidad_medida", $$v)},expression:"obj.unidad_medida"}})],1),_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Tipo de material")]),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Tipo de material","size":"small","disabled":""},model:{value:(_vm.obj.tipo_material),callback:function ($$v) {_vm.$set(_vm.obj, "tipo_material", $$v)},expression:"obj.tipo_material"}})],1),_c('div',{staticClass:"col-6 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Cantidad")]),_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Agregar cantidad","size":"small"},model:{value:(_vm.obj.cantidad),callback:function ($$v) {_vm.$set(_vm.obj, "cantidad", $$v)},expression:"obj.cantidad"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }