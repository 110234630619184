<template>
    <section class="section-ver-pedido container-fluid">
        <navbar-admin />
        <!-- info-top  -->
        <div v-if="detallePedido" class="info-top border bg-whitesmoke br-5 p-3">
            <div class="row">
                <div class="col-6">
                    <p class="text-5d f-600 f-18"> {{ detallePedido.proyecto }} </p>
                    <p class="f-14"> <span class="f-600s">Equipo:</span> {{ detallePedido.equipo }} </p>
                    <p class="f-14"> <span class="f-600s">Solicitante:</span> {{ detallePedido.solicita }} </p>
                </div>
                <div class="col-6">
                    <div class="d-flex justify-content-end">
                        <el-tooltip content="Editar pedido" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d"
                                :class="permitAction('almacen.pedido.editar.detalle') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click="functionPermitAction('almacen.pedido.editar.detalle', openModalEditOrder, [detallePedido])"
                            >
                                <i class="icon-pencil text-white" />
                            </button>
                        </el-tooltip>
                        <el-tooltip content="Eliminar pedido" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d ml-2"
                                :class="permitAction('almacen.pedido.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click="functionPermitAction('almacen.pedido.eliminar', openModalDeleteOrder, [detallePedido.id])"
                            >
                                <i class="icon-trash-can-outline text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                    <p class="text-white">Info blank</p>
                    <div class="d-flex justify-content-end">
                        <p class="f-14"> <span class="f-600">Fecha solicitud:</span> {{ detallePedido.fecha_solicitud | helper-fecha('DD MMM. YYYY') }} </p>
                        <p class="f-14 ml-2"> <span class="f-600">Fecha llegada:</span> {{ detallePedido.fecha_llegada | helper-fecha('DD MMM. YYYY') }} </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- titulo + add material + filtros -->
        <titulo-divisor titulo="Lista de materiales">
            <div class="row">
                <div class="col-auto pr-0">
                    <button class="btn btn-general text-white f-12 px-3"
                        :class="permitAction('almacen.pedido.agregar.material') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.pedido.agregar.material', openModaladdMaterial)"
                    >Añadir material</button>
                </div>
                <div class="col auto my-auto">
                    <input-search v-model="query" :search="beforeSearch" auto-search placeholder="Buscar" size="small" />
                </div>
                <div class="col-auto my-auto pl-1 icon-option">
                    <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="handleClearFilter"/>
                </div>
            </div>
        </titulo-divisor>
        <!-- tabla  -->
        <tabla-general 
            :usar-servidor="false" 
            :usar-paginacion="false"
            :mostrarBuscador="false" 
            altura-tabla="calc(100vh - 390px)" 
            :data="dataListadoPedidos"
        >
            <el-table-column label="Categoría" prop="categoria">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.categoria }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Material" prop="material" show-overflow-tooltip>
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos"> {{ scope.row.material }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Espesor" prop="espesor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.espesor }} </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M" prop="unidad_medida" width="100" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600"> {{ scope.row.unidad_medida }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2"> {{ scope.row.tipo_material }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cantidad" width="90" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted"> {{ scope.row.cantidad }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Estado" prop="estado">
                <template slot-scope="scope">
                    <el-select v-model="id_estado[scope.$index]" filterable placeholder="Seleccionar" @change="changeEstado($event, scope.row.id)" size="small" class="w-100">
                        <template slot="prefix">
                            <i class="icon-circle-fill f-17" style="position: relative; top: 5px;" :style="`color: ${getColor(id_estado[scope.$index])}`" />
                        </template>
                        <el-option v-for="item in estados" :key="item.id" :label="item.estado" :value="item.id">
                            <div class="d-flex">
                                <i class="icon-circle-fill" :style="`color: ${item.color}`" />
                                <p class="f-12 ml-1">{{ item.estado }}</p>
                            </div>
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="Proveedor" prop="proveedor">
                <template slot-scope="scope">
                    <el-select v-model="id_proveedor[scope.$index]" filterable placeholder="Seleccionar" @change="changeProveedor($event, scope.row.id)" size="small" class="w-100">
                        <el-option v-for="item in proveedores" :key="item.id" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column width="100" align="center">
                <template slot-scope="scope">
                    <el-tooltip content="Editar" effect="light" visible-arrow>
                        <i class="icon-pencil icon-general-hover f-18" 
                            :class="permitAction('almacen.pedido.editar.material') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.pedido.editar.material', openModalEditMaterial, [scope.row])"
                        />
					</el-tooltip>
					<el-tooltip content="Eliminar" effect="light" visible-arrow>
                        <i class="icon-trash-can icon-general-hover cr-pointer f-18"
                            :class="permitAction('almacen.pedido.editar.material') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @click="functionPermitAction('almacen.pedido.editar.material', openModalDeleteMaterial, [scope.row.id])"
                        />
                    </el-tooltip>
                </template>
            </el-table-column>
        </tabla-general>
        <div class="row justify-content-center">
            <div class="col-auto">
                <Pagination :pagination="pagination" layout="prev, pager, next" @paginate="Action_get_pedidos_internos_materiales(id_pedido)"/>
            </div>
        </div>
        <!-- partials -->
        <modal-edit-order ref="openModalEditOrder" />
        <modal-eliminar ref="openModalDeleteOrder" title="Eliminar pedido" mensaje="¿Está seguro que quiere eliminar este pedido?" @delete="handleDeleteOrder" />
        <modal-add-material ref="openModalAddMaterial" @update="setearDatos" />
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-3">
                    <p class="input-label-top">Categoría</p>
                    <el-select v-model="filter.id_categoria" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="item in filtro_materiales.categorias" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Material</p>
                    <el-select v-model="filter.id_material" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="item in filtro_materiales.materiales" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Espesor</p>
                    <el-select v-model="filter.id_espesor" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="item in filtro_materiales.espesor" :key="item.id" :label="item.espesor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="item in filtro_materiales.tipos_materiales" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de cantidad</p>
                    <el-slider v-model="range_unidades" range :min="filtro_materiales.cantidad_min" :max="filtro_materiales.cantidad_max" />
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filter.id_estado" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="item in filtro_materiales.estados" :key="item.id_estado" :label="item.estado" :value="item.id_estado" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Proveedor</p>
                    <el-select v-model="filter.id_proveedor" clearable filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="item in filtro_materiales.proveedores" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleClearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
        <modal-edit-material ref="openModalEditMaterial" />
        <modal-eliminar ref="openModalDeleteMaterial" title="Eliminar material" mensaje="¿Está seguro que quiere eliminar este material?" @delete="handleDeleteMaterial" />
    </section>
</template>

<script>
import modalEditOrder from './partials/modalEditOrder'
import modalAddMaterial from './partials/modalAddMaterial'
import modalEditMaterial from './partials/modalEditMaterial'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'verPedido',
    components: {
        modalEditOrder,
        modalAddMaterial,
        modalEditMaterial,
    },
    data() {
        return {
            color_material: '',
            idToDelete: null,
            id_estado: [],
            id_proveedor: [],
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            detallePedido: 'almacen/pedidos/pedido',
            dataListadoPedidos: 'almacen/pedidos/pedido_interno_material',
            proveedores: 'selects/selects/select_providers',
            filtro_materiales: 'almacen/pedidos/filtro_pedido_interno_material',
            estados: 'almacen/pedidos/estados'
        }),
        filter: {
            get(){
                return this.$store.getters['almacen/pedidos/filter_material']
            }, 
            set(val){
                this.$store.commit('almacen/pedidos/set_filter_material', val)
            }
        },
        pagination: {
            get(){
                return this.$store.getters['almacen/pedidos/pagination_material']
            }, 
            set(val){
                this.$store.commit('almacen/pedidos/set_pagination_material', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['almacen/pedidos/query']
            }, 
            set(val){
                this.$store.commit('almacen/pedidos/set_query', val)
            }
        },
        range_unidades: {
            get(){
                return [
                    this.filter.cantidad_min,
                    this.filter.cantidad_max
                ]
            },
            set(val){
                this.filter.cantidad_min = val[0]
                this.filter.cantidad_max = val[1]
            }
        },
        id_pedido(){
            return this.$route.params.id_pedido
        },
        filterCount(){
            return this.filter.count();
        },
    },
    async created () {
        this.getBreadcumbs([ 'almacen.main', 'almacen.pedidos.internos', 'almacen.pedidos.internos.estado.pedido' ])
        await Promise.all([
            this.Action_get_pedido(this.id_pedido),
            this.Action_get_select_providers(),
            this.Action_get_select_categorias(),
            this.Action_get_pedidos_internos_materiales_filtro(this.id_pedido),
            this.Action_get_estados()
        ])
        await this.clearFilter()
        await this.Action_get_pedidos_internos_materiales(this.id_pedido)
        this.setearDatos()
    },
    methods: {
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            Action_get_pedido: 'almacen/pedidos/Action_get_pedido',
            Action_get_pedidos_internos_materiales: 'almacen/pedidos/Action_get_pedidos_internos_materiales',
            Action_get_select_providers: 'selects/selects/Action_get_select_providers',
            Action_delete_pedido_interno_material: 'almacen/pedidos/Action_delete_pedido_interno_material',
            Action_get_select_categorias: 'selects/selects/Action_get_select_categorias',
            Action_get_pedidos_internos_materiales_filtro: 'almacen/pedidos/Action_get_pedidos_internos_materiales_filtro',
            Action_delete_pedido_interno: 'almacen/pedidos/Action_delete_pedido_interno',
            Action_update_estado_pedido_interno_material: 'almacen/pedidos/Action_update_estado_pedido_interno_material',
            Action_update_proveedor_pedido_interno_material: 'almacen/pedidos/Action_update_proveedor_pedido_interno_material',
            Action_get_estados: 'almacen/pedidos/Action_get_estados',
            clearFilter: 'almacen/pedidos/Action_clear_filter_material'
        }),
        setearDatos(){
            this.id_estado = []
            this.id_proveedor = []
            this.dataListadoPedidos.forEach(el => {
                this.id_estado.push(el.id_estado ?? '')
                this.id_proveedor.push(el.id_proveedor ?? '')
            })
        },
        async changeEstado(id_estado, id){
            await this.Action_update_estado_pedido_interno_material({
                id_pedido: this.id_pedido,
                id: id,
                ...{id_estado: id_estado}
            })
            this.Action_get_pedidos_internos_materiales_filtro(this.id_pedido)
        },
        async changeProveedor(id_proveedor, id){
            await this.Action_update_proveedor_pedido_interno_material({
                id_pedido: this.id_pedido,
                id: id,
                ...{id_proveedor: id_proveedor}
            })
            this.Action_get_pedidos_internos_materiales_filtro(this.id_pedido)
        },
        beforeSearch(){
            this.Action_get_pedidos_internos_materiales(this.id_pedido)
        },
        openModaladdMaterial() { 
            this.$refs.openModalAddMaterial.toggle()
        },
        openModalEditOrder(data) {
            this.$refs.openModalEditOrder.toggle(data)
        },
        openModalDeleteOrder(id) {
            this.idToDelete = id
            this.$refs.openModalDeleteOrder.toggle()
        },
        async handleDeleteOrder(){
            await this.Action_delete_pedido_interno(this.idToDelete)
            this.$router.push({ name: 'almacen.pedidos.internos.estado.pedido' })
        },
        abrirModalFiltros() { 
            this.$refs.abrirModalFiltros.toggle()
        },
        openModalEditMaterial(data) {
            this.$refs.openModalEditMaterial.toggle(data)
        },
        openModalDeleteMaterial(id) { 
            this.idToDelete = id
            this.$refs.openModalDeleteMaterial.toggle()
        },
        async handleDeleteMaterial(){
            await this.Action_delete_pedido_interno_material({id_pedido: this.id_pedido, id: this.idToDelete})
            this.Action_get_pedidos_internos_materiales(this.id_pedido)
        },
        handleClearFilter(){
            this.clearFilter()
            this.Action_get_pedidos_internos_materiales(this.id_pedido)
        },
        async filtrarMateriales() {
            await this.Action_get_pedidos_internos_materiales(this.id_pedido)
            this.$refs.abrirModalFiltros.toggle()
        },
        getColor(id){
            return this.estados.find(el => el.id == id)?.color ?? '#808080'
        }
    },


}
</script>

<style>

</style>